import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCWBDtUzJt3ge2Yiyhq-Stj8ToqqXeOnsI",
  authDomain: "pwa-push-3e100.firebaseapp.com",
  projectId: "pwa-push-3e100",
  storageBucket: "pwa-push-3e100.appspot.com",
  messagingSenderId: "715452077830",
  appId: "1:715452077830:web:5e8085a6bc2d3fabc57087",
  measurementId: "G-N6REKVBMNR"
};

firebase.initializeApp(firebaseConfig);
console.log('firebase', firebase);

const messaging = firebase.messaging();

messaging.usePublicVapidKey("BGUBQxGn16sWkLRJs1oNmrBFmo3YDLWmlwUk8XycMq-iEPlTWs07DFT50bZufGeRuEckG1worzo5J1w2y19TtRw");

console.log('messaging', messaging);

messaging.requestPermission().then(() => {
  console.log('permission granted');

  messaging.getToken().then(token => {
    console.log('token', token);
  });
}).catch(err => {
  console.log('unable to get permission');
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
